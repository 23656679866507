<template>
    <div class="header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled" style="">
      <!-- Google Tag Manager (noscript) -->
      <noscript>
         <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5FS8GGP" height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
      <!-- End Google Tag Manager (noscript) -->

      
   
      

      
      <!--begin::Main-->
      <!--begin::Header Mobile-->
      <div id="kt_header_mobile" class="header-mobile">
         <!--begin::Logo-->
         <a href="#">
         <img alt="Logo" src="assets/media/logos/LOGOMINI.png" class="logo-default max-h-60px">
         </a>
         <!--end::Logo-->
         <!--begin::Toolbar-->
         <div class="d-flex align-items-center">
            <button @click="mobileMenu = !mobileMenu" class="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
            <span></span>
            </button>
         </div>
         <!--end::Toolbar-->
      </div>
      <!--end::Header Mobile-->
      <div class="d-flex flex-column flex-root">
         <!--begin::Page-->
         <div class="d-flex flex-row flex-column-fluid page">
            <!--begin::Aside-->
            <div class="aside aside-left d-flex aside-fixed" :class="{'aside-on': mobileMenu}" id="kt_aside" style="">
               <!--begin::Primary-->
               <div class="aside-primary d-flex flex-column align-items-center flex-row-auto">
                  <!--begin::Brand-->
                  <div class="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
                     <!--begin::Logo-->
                     <a href="">
                     <img alt="Logo" src="assets/media/logos/LOGOMINI.png" class="max-h-60px">
                     </a>
                     <!--end::Logo-->
                  </div>
                  <!--end::Brand-->
                  <!--begin::Nav Wrapper-->
                  <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull ps" style="height: 689px; overflow: hidden;">
                     <!--begin::Nav-->
                     <ul class="nav flex-column" role="tablist">
                        <!--begin::Item-->   
                        <li class="nav-item mb-3 tool-tip" data-bs-trigger="hover" data-container="body" data-boundary="window" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                           <span class="tooltiptext">Dashboard</span>
                           <router-link to="/home" class="nav-link btn btn-icon btn-clean btn-lg" :class="{'active': $route.path == '/home'}" data-toggle="tab" data-target="#kt_aside_tab_1" role="tab">
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"></rect>
                                       <rect fill="#000000" x="4" y="4" width="7" height="7" rx="1.5"></rect>
                                       <path d="M5.5,13 L9.5,13 C10.3284271,13 11,13.6715729 11,14.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,14.5 C4,13.6715729 4.67157288,13 5.5,13 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,9.5 C20,10.3284271 19.3284271,11 18.5,11 L14.5,11 C13.6715729,11 13,10.3284271 13,9.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z M14.5,13 L18.5,13 C19.3284271,13 20,13.6715729 20,14.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,14.5 C13,13.6715729 13.6715729,13 14.5,13 Z" fill="#000000" opacity="0.3"></path>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </router-link>
                        </li>
                        <!--end::Item-->
                        
                        <!--begin::Item-->
                        <li class="nav-item mb-3 tool-tip" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Management">
                           <span class="tooltiptext">Settings</span>
                           <router-link to="/settings" class="nav-link btn btn-icon btn-clean btn-lg"  :class="{'active': $route.path == '/settings'}" >
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"></rect>
                                       <path d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z" fill="#000000"></path>
                                       <path d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z" fill="#000000" opacity="0.3"></path>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </router-link>
                        </li>
                        <!--end::Item-->

                        <!--begin::Item-->
                        <li class="nav-item mb-3 tool-tip" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Management">
                           <span class="tooltiptext">Referal</span>
                           <router-link to="/referal" class="nav-link btn btn-icon btn-clean btn-lg"  :class="{'active': $route.path == '/referal'}" >
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"/>
                                       <path d="M12.4644661,14.5355339 L9.46446609,14.5355339 C8.91218134,14.5355339 8.46446609,14.9832492 8.46446609,15.5355339 C8.46446609,16.0878187 8.91218134,16.5355339 9.46446609,16.5355339 L12.4644661,16.5355339 L12.4644661,17.5355339 C12.4644661,18.6401034 11.5690356,19.5355339 10.4644661,19.5355339 L6.46446609,19.5355339 C5.35989659,19.5355339 4.46446609,18.6401034 4.46446609,17.5355339 L4.46446609,13.5355339 C4.46446609,12.4309644 5.35989659,11.5355339 6.46446609,11.5355339 L10.4644661,11.5355339 C11.5690356,11.5355339 12.4644661,12.4309644 12.4644661,13.5355339 L12.4644661,14.5355339 Z" fill="#000000" opacity="0.3" transform="translate(8.464466, 15.535534) rotate(-45.000000) translate(-8.464466, -15.535534) "/>
                                       <path d="M11.5355339,9.46446609 L14.5355339,9.46446609 C15.0878187,9.46446609 15.5355339,9.01675084 15.5355339,8.46446609 C15.5355339,7.91218134 15.0878187,7.46446609 14.5355339,7.46446609 L11.5355339,7.46446609 L11.5355339,6.46446609 C11.5355339,5.35989659 12.4309644,4.46446609 13.5355339,4.46446609 L17.5355339,4.46446609 C18.6401034,4.46446609 19.5355339,5.35989659 19.5355339,6.46446609 L19.5355339,10.4644661 C19.5355339,11.5690356 18.6401034,12.4644661 17.5355339,12.4644661 L13.5355339,12.4644661 C12.4309644,12.4644661 11.5355339,11.5690356 11.5355339,10.4644661 L11.5355339,9.46446609 Z" fill="#000000" transform="translate(15.535534, 8.464466) rotate(-45.000000) translate(-15.535534, -8.464466) "/>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </router-link>
                        </li>
                        <!--end::Item-->

                        <!--begin::Item-->
                        <li class="nav-item mb-3 tool-tip" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Management">
                           <span class="tooltiptext">PNL</span>
                           <router-link to="/pnl" class="nav-link btn btn-icon btn-clean btn-lg"  :class="{'active': $route.path == '/pnl'}" >
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"/>
                                       <path d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z" fill="#000000" fill-rule="nonzero"/>
                                       <path d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </router-link>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="nav-item mb-3 tool-tip" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Management">
                           <span class="tooltiptext">Plans</span>
                           <router-link to="/plans" class="nav-link btn btn-icon btn-clean btn-lg"  :class="{'active': $route.path == '/plans'}" >
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                     <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"/>
                                       <path d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z" fill="#000000" opacity="0.3"/>
                                       <polygon fill="#000000" points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"/>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </router-link>
                        </li>
                        <!--end::Item-->
                        <!--begin::Item-->
                        <li class="nav-item mb-3 tool-tip" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Management">
                           <span class="tooltiptext">Discord</span>
                           <div style="cursor: pointer;" class="nav-link btn btn-icon btn-clean btn-lg" onclick="window.open('https://discord.gg/XYPM7EbXqk', '_blank');">
                              <span class="svg-icon svg-icon-xl">
                                 <!--begin::Svg Icon | path:assets/media/svg/icons/Home/Library.svg-->
                                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                       <rect x="0" y="0" width="24" height="24"/>
                                       <path d="M16,15.6315789 L16,12 C16,10.3431458 14.6568542,9 13,9 L6.16183229,9 L6.16183229,5.52631579 C6.16183229,4.13107011 7.29290239,3 8.68814808,3 L20.4776218,3 C21.8728674,3 23.0039375,4.13107011 23.0039375,5.52631579 L23.0039375,13.1052632 L23.0206157,17.786793 C23.0215995,18.0629336 22.7985408,18.2875874 22.5224001,18.2885711 C22.3891754,18.2890457 22.2612702,18.2363324 22.1670655,18.1421277 L19.6565168,15.6315789 L16,15.6315789 Z" fill="#000000"/>
                                       <path d="M1.98505595,18 L1.98505595,13 C1.98505595,11.8954305 2.88048645,11 3.98505595,11 L11.9850559,11 C13.0896254,11 13.9850559,11.8954305 13.9850559,13 L13.9850559,18 C13.9850559,19.1045695 13.0896254,20 11.9850559,20 L4.10078614,20 L2.85693427,21.1905292 C2.65744295,21.3814685 2.34093638,21.3745358 2.14999706,21.1750444 C2.06092565,21.0819836 2.01120804,20.958136 2.01120804,20.8293182 L2.01120804,18.32426 C1.99400175,18.2187196 1.98505595,18.1104045 1.98505595,18 Z M6.5,14 C6.22385763,14 6,14.2238576 6,14.5 C6,14.7761424 6.22385763,15 6.5,15 L11.5,15 C11.7761424,15 12,14.7761424 12,14.5 C12,14.2238576 11.7761424,14 11.5,14 L6.5,14 Z M9.5,16 C9.22385763,16 9,16.2238576 9,16.5 C9,16.7761424 9.22385763,17 9.5,17 L11.5,17 C11.7761424,17 12,16.7761424 12,16.5 C12,16.2238576 11.7761424,16 11.5,16 L9.5,16 Z" fill="#000000" opacity="0.3"/>
                                    </g>
                                 </svg>
                                 <!--end::Svg Icon-->
                              </span>
                           </div>
                        </li>
                        <!--end::Item-->
                     </ul>
                     <!--end::Nav-->
                     <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                        <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                     </div>
                     <div class="ps__rail-y" style="top: 0px; right: -2px;">
                        <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                     </div>
                  </div>
                  <!--end::Nav Wrapper-->
                  <!--begin::Footer-->
                  <div class="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                    
                     
                     <!--begin::User-->
                     <a @click="logout" class="btn btn-icon btn-clean btn-lg w-40px h-40px" id="kt_quick_user_toggle" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="User Profile">
                        <span class="symbol symbol-30 symbol-lg-40">
                           <span class="svg-icon svg-icon-xl">
                              <!--begin::Svg Icon | path:assets/media/svg/icons/General/User.svg-->
                              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M14.0069431,7.00607258 C13.4546584,7.00607258 13.0069431,6.55855153 13.0069431,6.00650634 C13.0069431,5.45446114 13.4546584,5.00694009 14.0069431,5.00694009 L15.0069431,5.00694009 C17.2160821,5.00694009 19.0069431,6.7970243 19.0069431,9.00520507 L19.0069431,15.001735 C19.0069431,17.2099158 17.2160821,19 15.0069431,19 L3.00694311,19 C0.797804106,19 -0.993056895,17.2099158 -0.993056895,15.001735 L-0.993056895,8.99826498 C-0.993056895,6.7900842 0.797804106,5 3.00694311,5 L4.00694793,5 C4.55923268,5 5.00694793,5.44752105 5.00694793,5.99956624 C5.00694793,6.55161144 4.55923268,6.99913249 4.00694793,6.99913249 L3.00694311,6.99913249 C1.90237361,6.99913249 1.00694311,7.89417459 1.00694311,8.99826498 L1.00694311,15.001735 C1.00694311,16.1058254 1.90237361,17.0008675 3.00694311,17.0008675 L15.0069431,17.0008675 C16.1115126,17.0008675 17.0069431,16.1058254 17.0069431,15.001735 L17.0069431,9.00520507 C17.0069431,7.90111468 16.1115126,7.00607258 15.0069431,7.00607258 L14.0069431,7.00607258 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(9.006943, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-9.006943, -12.000000) "></path>
                                    <rect fill="#000000" opacity="0.3" transform="translate(14.000000, 12.000000) rotate(-270.000000) translate(-14.000000, -12.000000) " x="13" y="6" width="2" height="12" rx="1"></rect>
                                    <path d="M21.7928932,9.79289322 C22.1834175,9.40236893 22.8165825,9.40236893 23.2071068,9.79289322 C23.5976311,10.1834175 23.5976311,10.8165825 23.2071068,11.2071068 L20.2071068,14.2071068 C19.8165825,14.5976311 19.1834175,14.5976311 18.7928932,14.2071068 L15.7928932,11.2071068 C15.4023689,10.8165825 15.4023689,10.1834175 15.7928932,9.79289322 C16.1834175,9.40236893 16.8165825,9.40236893 17.2071068,9.79289322 L19.5,12.0857864 L21.7928932,9.79289322 Z" fill="#000000" fill-rule="nonzero" transform="translate(19.500000, 12.000000) rotate(-90.000000) translate(-19.500000, -12.000000) "></path>
                                 </g>
                              </svg>
                              <!--end::Svg Icon-->
                           </span>
                           <!--<span class="symbol-label font-size-h5 font-weight-bold">S</span>-->
                        </span>
                     </a>
                     <!--end::User-->
                  </div>
                  <!--end::Footer-->
               </div>
               <!--end::Primary-->
               <!--begin::Secondary-->
               <div class="aside-secondary d-flex flex-row-fluid">
                  <!--begin::Workspace-->
                  <div class="aside-workspace scroll scroll-push my-2 ps" style="height: 900px; overflow: hidden;">
                     <!--begin::Tab Content-->
                     <div class="tab-content">
                        <!--begin::Tab Pane-->
                        <div class="tab-pane py-lg-5 fade show active" id="kt_aside_tab_1">
                           <!--begin::Form-->
                           <!--end::Form-->
                           <h3 class="p-2 p-lg-3 my-1 my-lg-3" style="color:#fff;">Modules</h3>
                           <!--begin::List-->
                           <div class="list list-hover p-3 px-lg-7 ">
                              <!--begin::Item-->
                              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                                 <div class="d-flex align-items-center">
                                    <!--begin::Symbol-->
                                    <span class="switch switch-lg switch-icon">
                                       <label>
                                          <input v-model="Payload.User.Setting.EnableLongTermTrades" type="checkbox" checked="checked" name="select">
                                          <span></span>
                                       </label>
                                    </span>
                                    <!--end::Symbol-->
                                    <!--begin::Text-->
                                    <div class="d-flex flex-column flex-grow-1 ml-4 mr-2">
                                       <span class="text-dark-75 font-size-h6 mb-0">[Spot] Trading   </span>
                                       <a class="text-muted text-hover-primary font-weight-bold">Day trading positions</a>
                                    </div>
                                    <!--begin::End-->
                                 </div>
                              </div>
                              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                                 <div class="d-flex align-items-center">
                                    <!--begin::Symbol-->
                                    <span class="switch switch-lg switch-icon">
                                       <label>
                                          <input v-model="Payload.User.Setting.EnableShortTermTrades" type="checkbox" checked="checked" name="select">
                                          <span></span>
                                       </label>
                                    </span>
                                    <!--end::Symbol-->
                                    <!--begin::Text-->
                                    <div class="d-flex flex-column flex-grow-1 ml-4 mr-2">
                                       <span class="text-dark-75 font-size-h6 mb-0">[Spot] Scalping</span>
                                       <a class="text-muted text-hover-primary font-weight-bold">High frequency trades</a>
                                    </div>
                                    <!--begin::End-->
                                 </div>
                              </div>
                             
                              <!--end::Item-->

                            <!--  <div class="list-item hoverable p-2 p-lg-3 mb-2">
                                 <div class="d-flex align-items-center">
                                    
                                    <span class="switch switch-lg switch-icon">
                                       <label>
                                          <input v-model="Payload.User.Setting.EnableNewListings" type="checkbox" checked="checked" name="select">
                                          <span></span>
                                       </label>
                                    </span>
                                    
                                    <div class="d-flex flex-column flex-grow-1 ml-4 mr-2">
                                       <span class="text-dark-75 font-size-h6 mb-0">Coinbase Pro Listings</span>
                                       <a class="text-muted text-hover-primary font-weight-bold">Sell when listed, buy before</a>
                                    </div>
                                   
                                 </div>
                              </div> -->
                              <!--end::Item-->

                              <div class="list-item hoverable p-2 p-lg-3 mb-2">
                                 <div class="d-flex align-items-center">
                                    <!--begin::Symbol-->
                                    <span class="switch switch-lg switch-icon">
                                       <label>
                                          <input v-model="Payload.User.Setting.EnableDeFiTrades" type="checkbox" checked="checked" name="select">
                                          <span></span>
                                       </label>
                                    </span>
                                    <!--end::Symbol-->
                                    <!--begin::Text-->
                                    <div class="d-flex flex-column flex-grow-1 ml-4 mr-2">
                                       <span class="text-dark-75 font-size-h6 mb-0">DeFi</span>
                                       <a class="text-muted text-hover-primary font-weight-bold">Uniswap Gems</a>
                                    </div>
                                    <!--begin::End-->
                                 </div>
                              </div>
                              <!--end::Item-->
                              
                              
                           </div>
                           <!--end::List-->

                           <div v-show="Membership == 'Free'" class="card card-custom card-stretch gutter-b" style="position: absolute; bottom: 0; height: auto; width: 80%; margin-bottom: 0!important; margin:10%">
                                <div class="card-body d-flex p-0 card-rounded">
                                    <div class="flex-grow-1 p-10 card-rounded flex-grow-1 bgi-no-repeat" style="background-color: rgb(34, 40, 49); box-shadow: 0px 0px 20px #2b333e !important;">
                                        <h4 class="text-inverse-danger mt-2 font-weight-bolder">&#11088; Upgrade your plan!</h4>
                                        <div class="mt-5">
                                            <div class="d-flex mb-5">
                                                <span class="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                                                    <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                                <span class="text-white">Trailing</span>
                                            </div>
                                            <div class="d-flex mb-5">
                                                <span class="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                                                    <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                                <span class="text-white">Budget Settings</span>
                                            </div>
                                            <div class="d-flex">
                                                <span class="svg-icon svg-icon-md svg-icon-white flex-shrink-0 mr-3">
                                                    <!--begin::Svg Icon | path:/metronic/theme/html/demo12/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon points="0 0 24 0 24 24 0 24"></polygon>
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)" x="11" y="5" width="2" height="14" rx="1"></rect>
                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"></path>
                                                        </g>
                                                    </svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                                <span class="text-white">More Signals</span>
                                            </div>
                                        </div>
                                          <router-link to="/plans" :class="{'active': $route.path == '/plans'}" data-toggle="tab" data-target="#kt_aside_tab_1" role="tab">
                                             <div class="mt-5"><a class="btn btn-primary font-weight-bold py-2 px-6">Upgrade now from $99/mo</a></div>
                                          </router-link>
												</div>
											</div>
										</div>
                        </div>
                        <!--end::Tab Pane-->
                        <!--begin::Tab Pane-->
                        
                     </div>
                     <!--end::Tab Content-->
                     <div class="ps__rail-x" style="left: 0px; bottom: 0px;">
                        <div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
                     </div>
                     <div class="ps__rail-y" style="top: 0px; right: 5px;">
                        <div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
                     </div>
                  </div>
                  <!--end::Workspace-->
               </div>
               <!--end::Secondary-->
            </div>
            <!--end::Aside-->
            <!--begin::Wrapper-->
            <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
               <!--begin::Content-->
               <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                  <!--begin::Subheader-->
                  <div class="subheader py-3 py-lg-8 subheader-transparent" id="kt_subheader">
                     <div class="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                        <!--begin::Info-->
                        <div class="d-flex align-items-center mr-1">
                           <!--begin::Page Heading-->
                           <div class="d-flex align-items-baseline flex-wrap mr-5">
                              <!--begin::Page Title-->
                              <h2 class="d-flex align-items-center text-dark font-weight-bold my-1 mr-3">{{$route.name}}</h2>
                              <!--end::Page Title-->
                           </div>
                           <!--end::Page Heading-->
                        </div>
                        <!--end::Info-->
                        <!--begin::Toolbar-->
                        <div v-show="$route.path.includes('pnl')" class="toolbar py-2" id="kt_toolbar">
						<!--begin::Container-->
						<div id="kt_toolbar_container" class="container-fluid d-flex align-items-center">

							
							<!--begin::Action group-->
							<div class="d-flex align-items-center flex-wrap">
								<!--begin::Wrapper-->
								<div class="flex-shrink-0 me-2">
									<v-md-date-range-picker
                           :auto-apply="false"
                           :opens="'right'"
                           @change="datePickerChange"
                           :start-date="(new Date(Date.now()-(30*24*60*60*1000))).toISOString().split('T')[0]"
                           :end-date="(new Date(Date.now()-(24*60*60*1000))).toISOString().split('T')[0]"
                           
                           ></v-md-date-range-picker>
								</div>
								<!--end::Wrapper-->
								
							</div>
							<!--end::Action group-->
						</div>
						<!--end::Container-->
					</div>
                        <!--end::Toolbar-->
                     </div>
                  </div>
                  <!--end::Subheader-->
                  <!--begin::Entry-->
                  <div class="d-flex flex-column-fluid">
                     <router-view></router-view>
                  </div>
                  <!--end::Entry-->
               </div>
               <!--end::Content-->
               <!--begin::Footer-->
               <!--doc: add "bg-white" class to have footer with solod background color-->
               <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
                  <!--begin::Container-->
                  <div class="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                     <!--begin::Copyright-->
                     <div class="text-dark order-2 order-md-1">
                        <span class="text-muted font-weight-bold mr-2">2021©</span>
                        <a href="" target="_blank" class="text-dark-75 text-hover-primary">TrustSignals</a>
                     </div>
                     <!--end::Copyright-->
                     <!--begin::Nav-->
                     
                     <!--end::Nav-->
                  </div>
                  <!--end::Container-->
               </div>
               <!--end::Footer-->
            </div>
            <!--end::Wrapper-->
         </div>
         <!--end::Page-->
      </div>
      <!--end::Main-->
      
      
      
      
      
   </div>
</template>

<script>
import Maurer from './../../components/Maurer.vue';

export default {
    beforeCreate(){
        document.getElementById("maurer_app").className = 'header-mobile-fixed subheader-enabled aside-enabled aside-fixed aside-secondary-enabled';
    },

    data(){
        return {
            pnl_period: 'month',
            mobileMenu: true,
            Membership: 'Unknown',
            UserID: '',
            Payload: {
                NewPassword: '',
                OldPassword: '',
                User: {
                    Setting: {

                        // Binance keys
                        BinanceApiKey: '',
                        BinanceSecretKey: '',

                        EnableLongTermTrades: null,
                        LongTermTradesStopLossPercentage: '',
                        LongTermTradesStopStepPercentage: '',
                        LongTermTradesLimitFromStopPercentage: '',
                        LongTermTradesMaximumTakeProfitPercentage: '',
                        LongTermTradesAmountToTrade: '',
                        LongTermTradesPreferredCoin: '',
                        LongTermTradesMaximumInvestedAmount: '',
                        LongTermTradesMaximumDailyInvestedAmount: '',
                        LongTermTradesEnableCompounding: false,

                        //Short Term Trades
                        EnableShortTermTrades: null,
                        ShortTermTradesStopLossPercentage: '',
                        ShortTermTradesStopStepPercentage: '',
                        ShortTermTradesLimitFromStopPercentage: '',
                        ShortTermTradesMaximumTakeProfitPercentage: '',
                        ShortTermTradesAmountToTrade: '',
                        ShortTermTradesPreferredCoin: '',
                        ShortTermTradesMaximumInvestedAmount: '',
                        ShortTermTradesMaximumDailyInvestedAmount: '',
                        ShortTermTradesEnableCompounding: false,

                        //Valori Flash Sales
                        EnableFlashPump: null,
                        FlashPumpTakeProfitPercentage: '',
                        FlashPumpStopLossPercentage: '',
                        FlashPumpAmountToTrade: '',
                        FlashPumpPreferredCoin: '',

                        // Valori Coinbase
                        EnableNewListings: null,
                        NewListingsStopLossPercentage: '',
                        NewListingsStopStepPercentage: '',
                        NewListingsLimitFromStopPercentage: '',
                        NewListingsMaximumTakeProfitPercentage: '',
                        NewListingsAmountToTrade: '',
                        NewListingsPreferredCoin: '',

                        // Valori DeFi
                        EnableDeFiTrades: null,
                        DeFiTradesStopLossPercentage: '',
                        DeFiTradesStopStepPercentage: '',
                        DeFiTradesLimitFromStopPercentage: '',
                        DeFiTradesAmountToTrade: ''

                    },

                    TwoFactorAuthEnabled: false
                },

                Balance: 0
            }
        }
    },

    methods: {

        datePickerChange(values){
           this.pnl_period = values;

        },
        logout(){
            var vm = this;
            localStorage.clear();
            vm.$router.push('/login');
        },

        upgradeSubscription(){
            var vm = this;
            Maurer.api.user.upgrade({Method: 'CoinBase'},function (d) {
                window.open(d.url)
            
            }, function (d) {
                Maurer.api.error(d);
            });
        },

        init(){
           var vm = this;
            Maurer.api.user.get(function (d) {
            
            
            vm.Payload.User.Setting = d.data.Setting;
            vm.Payload.User.Permission = d.data.Permission;
            vm.Payload.User.Balance = d.data.Balance;
            vm.UserID = d.data.ID;
            vm.Payload.User.TwoFactorAuthEnabled = d.data.TwoFactorAuthEnabled;
            vm.Membership = 'Free';

            if(d.data.Permission.VipExpire != undefined && ((new Date(d.data.Permission.VipExpire)).getTime() > Date.now())){
                vm.Membership = 'VIP';
            }

            if(d.data.Permission.Priority){
                vm.Membership = 'Priority';
            }

            if(d.data.Permission.Owner){
                vm.Membership = 'Owner';
            }

            console.log(vm.Membership)

            
        }, function (d) {
            Maurer.api.error(d);
        });
        },

        editSettings(){
            var vm = this;
            vm.Payload.User.Setting.LongTermTradesStopLossPercentage = parseFloat(vm.Payload.User.Setting.LongTermTradesStopLossPercentage);
            vm.Payload.User.Setting.LongTermTradesStopStepPercentage = parseFloat(vm.Payload.User.Setting.LongTermTradesStopStepPercentage);
            vm.Payload.User.Setting.LongTermTradesLimitFromStopPercentage = parseFloat(vm.Payload.User.Setting.LongTermTradesLimitFromStopPercentage);
            vm.Payload.User.Setting.LongTermTradesMaximumTakeProfitPercentage = parseFloat(vm.Payload.User.Setting.LongTermTradesMaximumTakeProfitPercentage);
            vm.Payload.User.Setting.LongTermTradesAmountToTrade = parseFloat(vm.Payload.User.Setting.LongTermTradesAmountToTrade);
            vm.Payload.User.Setting.ShortTermTradesStopLossPercentage = parseFloat(vm.Payload.User.Setting.ShortTermTradesStopLossPercentage);
            vm.Payload.User.Setting.ShortTermTradesStopStepPercentage = parseFloat(vm.Payload.User.Setting.ShortTermTradesStopStepPercentage);
            vm.Payload.User.Setting.ShortTermTradesLimitFromStopPercentage = parseFloat(vm.Payload.User.Setting.ShortTermTradesLimitFromStopPercentage);
            vm.Payload.User.Setting.ShortTermTradesMaximumTakeProfitPercentage = parseFloat(vm.Payload.User.Setting.ShortTermTradesMaximumTakeProfitPercentage);
            vm.Payload.User.Setting.ShortTermTradesAmountToTrade = parseFloat(vm.Payload.User.Setting.ShortTermTradesAmountToTrade);
            vm.Payload.User.Setting.FlashPumpTakeProfitPercentage = parseFloat(vm.Payload.User.Setting.FlashPumpTakeProfitPercentage);
            vm.Payload.User.Setting.FlashPumpStopLossPercentage = parseFloat(vm.Payload.User.Setting.FlashPumpStopLossPercentage);
            vm.Payload.User.Setting.FlashPumpAmountToTrade = parseFloat(vm.Payload.User.Setting.FlashPumpAmountToTrade);
            
            
            Maurer.api.user.edit(vm.Payload, function (d) {
                // Rimuovo eventualmente la password dall'oggetto una volta ottenuta la risposta
                vm.Payload.OldPassword = undefined;
                vm.Payload.NewPassword = undefined;
                vm.init();
                ////Maurer.api.success(d);
            }, function (d) {
                // Rimuovo eventualmente la password dall'oggetto una volta ottenuta la risposta
                vm.Payload.OldPassword = undefined;
                vm.Payload.NewPassword = undefined;

                Maurer.api.error(d);
            });
        },


    },

    watch: {
        '$route.path'(){
           var vm = this;
           vm.mobileMenu = false;
        },

        'Payload.User.Setting.EnableLongTermTrades'(n, o){
           var vm = this;
           if(o == null){
              return;
           }
           Maurer.api.user.edit({
              User: {
                 Setting: {
                    EnableLongTermTrades: n
                 }
              }
           }, function (d) {
                
            }, function (d) {
                Maurer.api.error(d);
            });
        },

        'Payload.User.Setting.EnableNewListings'(n, o){
           var vm = this;
           if(o == null){
              return;
           }
           Maurer.api.user.edit({
              User: {
                 Setting: {
                    EnableNewListings: n
                 }
              }
           }, function (d) {
                
            }, function (d) {
                Maurer.api.error(d);
            });
        },

        'Payload.User.Setting.EnableDeFiTrades'(n, o){
           var vm = this;
           if(o == null){
              return;
           }
           Maurer.api.user.edit({
              User: {
                 Setting: {
                    EnableDeFiTrades: n
                 }
              }
           }, function (d) {
                
            }, function (d) {
                Maurer.api.error(d);
            });
        },

        'Payload.User.Setting.EnableShortTermTrades'(n, o){
           var vm = this;
           if(o == null){
              return;
           }
           Maurer.api.user.edit({
              User: {
                 Setting: {
                    EnableShortTermTrades: n
                 }
              }
           }, function (d) {
                
            }, function (d) {
                Maurer.api.error(d);
            });
        },

        'Payload.User.Setting.EnableFlashPump'(n, o){
           var vm = this;
           if(o == null){
              return;
           }
           Maurer.api.user.edit({
              User: {
                 Setting: {
                    EnableFlashPump: n
                 }
              }
           }, function (d) {
                
            }, function (d) {
                Maurer.api.error(d);
            });
        }
    },

    mounted(){
        var vm = this;
        vm.init();
    },
}
</script>

<style>
.date-sel-active {
   border: 1px solid #1bb08a!important;
   border-radius: 6px!important;
   margin-left: 10px;
}

.date-sel {
   border: 1px solid #47505e!important;
   border-radius: 6px!important;
   margin-left: 10px;
}



/** DATE PICKER */

.mdrp__activator .activator-wrapper .text-field {
    width: 100%!important;
    height: calc(1.5em + 1.3rem + 2px)!important;
    padding: .65rem 1rem!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    color: #dbdbde!important;
    background-color: #2b333e!important;
    background-clip: padding-box!important;
    border: 1px solid #2b333e!important;
    border-radius: .42rem!important;
    box-shadow: none!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    cursor:pointer;
}

.mdrp__activator .activator-wrapper .text-field:focus {
    color: #e8e9f0;
    background-color: #47505e;
    border-color: #1DB489;
    outline: 0;
}

.mdrp__activator .bar, .activator-wrapper label {
   display: none!important;
}

.ranges li.active {
   color: #1bae89!important;
}

.ranges li:hover {
   color: #1bae89!important;
   background-color: unset!important;
}





  .mdrp__panel[data-v-7863e830] {
    padding: 20px !important;
    border-radius: 5px !important;
  }

.mdrp__panel {
   background-color: #39424f !important;
   border: none!important;
   box-shadow: 0px 0px 50px #222831 !important;
}

.mdrp__panel .btn.btn-success {
   background: linear-gradient(80deg,#11998e,#38ef7d);
   border: 0px solid;
}

.mdrp__panel .btn.btn-default {
   border: 1px solid #1bb08a!important;
   border-radius: 6px!important;
   background: transparent;
   color: #1bb08a;
}

.mdrp__panel table.calendar-table td.calendar-cell.off {
  background-color: #2b333e!important;
  color: #b5b5c3!important;
}

.mdrp__panel table.calendar-table td.calendar-cell.active .calendar-cell__content {
  background: linear-gradient(80deg,#11998e,#38ef7d);
}

.mdrp__panel table.calendar-table td.calendar-cell.in-range {
  background-color: #1bb08b75!important;
  color: white!important;
}

.mdrp__panel table.calendar-table td.calendar-cell:hover:not(.in-range) {
  background: linear-gradient(80deg,#11998e,#38ef7d)!important;
}

.mdrp-root {
    width: 100%!important;
}



.btn.btn-default.focus:not(.btn-text), .btn.btn-default:focus:not(.btn-text), .btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled) {
  color: #df6f6f;
  background-color: #323b46;
  border-color: #f3f6f9;
}

.mdrp__panel .btn.btn-success {
  background: linear-gradient(80deg,#11998e,#38ef7d);
  border: 0px solid;
  width: 100%;
  margin-bottoM: 10px;
}

.mdrp__panel .btn.btn-success:hover {
  opacity:0.8;
  border: 0px solid;
  width: 100%;
  margin-bottoM: 10px;
}

.mdrp__panel .btn.btn-default {
  border: 1px solid #df6f6f !important;
  border-radius: 6px !important;
  background: transparent;
  color: #df6f6f;
  width: 100%;
}




.option.active[data-v-53264ffc] {
  border: 1px solid #1bb08a !important;
  background: #272e37;
}

.modal-footer {
  border-top: 0px solid #33e47f !important;
}

.modal-header {
  border-bottom: 0px solid #33e47f !important;
}

.modal-content {
  background-color: #39424f !important;
  color: #fff !important;
}


.modal-content {
  box-shadow: 0px 0px 30px #1a1f26;
}

.modal {
  display: block;
  background: #0000005c;
}

a.text-primary:focus, a.text-primary:hover {
  color: #fff !important;
  background: #272d35;
}

.modal .modal-header .close .ki, .modal .modal-header .close i {
  transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  color: #fff;
  font-size: .8rem;
}

.swal2-styled.swal2-confirm {
  border: 0;
    border-right-color: currentcolor;
    border-left-color: currentcolor;
  border-radius: .25em;
  background: initial;
    background-color: initial;
  background-color: #167361 !important;
  color: #fff;
  font-size: 1em;
  width: 100% !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  position: absolute;
  z-index: 2;
  top: -.25em;
  left: -.25em;
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  border: .25em solid rgb(22, 115, 97) !important;
  border-radius: 50%;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  display: block;
  position: absolute;
  z-index: 2;
  height: .3125em;
  border-radius: .125em;
  background-color: #fff !important;
}

.date-sel-active {
  border: 1px solid #47505e !important;
  border-radius: 6px !important;
  margin-left: 10px;
  background: #2f3742;
  color: #cacad5 !important;
  padding: 17px;
}

.checkbox > span {
  background-color: #2b333e !important;
  border: 1px solid transparent;
}

.checkbox.checkbox-outline > span {
  background-color: #2b333e !important;
  border-color: #2b333e !important;
}

.plan-switch {
  border: 1px solid #fff;
  border-radius: 6px;
}

.nav .nav-link {
  color: #fff;
}

.btn.focus, .btn:focus:not(.btn-text), .btn:hover:not(.btn-text) {
  transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
  
}

.swal2-content {
  z-index: 1;
  justify-content: center;
  margin: 0;
    margin-top: 0px;
  padding: 0px;
  color: #545454;
  font-size: 1.125em;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  word-wrap: break-word;
}


.swal2-file, .swal2-input, .swal2-textarea {
display: block;
width: 100%;
height: calc(1.5em + 1.3rem + 2px);
padding: .65rem 1rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #dbdbde;
background-color: #222831;
background-clip: padding-box;
border: 1px solid #222831;
border-radius: .42rem;
  border-top-right-radius: 0.42rem;
  border-bottom-right-radius: 0.42rem;
box-shadow: none;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.swal2-styled.swal2-cancel {
  border: 1px solid #df6f6f !important;

background: transparent;
color: #df6f6f;
width:100%;
}

.swal2-file:focus, .swal2-input:focus, .swal2-textarea:focus {
  border: 1px solid #1db489;
  outline: 0;
  box-shadow: 0px 0px;
  background: #47505e;
}

.scroll.scroll-pull {
   width: 290px;
}

.tool-tip {
  position: relative;
  display: inline-block;
}

.tool-tip .tooltiptext {
  visibility: hidden;
  min-width: max-content;
  background-color: #222831;
  color: lightgray;
  border-radius: 6px;
  padding: 8px 14px;
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 110%;
}

.tool-tip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #222831 transparent transparent;
}

.tool-tip:hover .tooltiptext {
  visibility: visible;
}
</style>